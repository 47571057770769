(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/ui/form/assets/javascripts/components/input.js') >= 0) return;  svs.modules.push('/components/ui/form/assets/javascripts/components/input.js');
"use strict";

const _excluded = ["className", "id", "label", "selectOnFocus", "prefix", "suffix", "hasFormData", "size", "refElem", "isSmall"];
function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var n = Object.getOwnPropertySymbols(e); for (r = 0; r < n.length; r++) o = n[r], -1 === t.indexOf(o) && {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (-1 !== e.indexOf(n)) continue; t[n] = r[n]; } return t; }

const {
  Component
} = React;
class Input extends Component {
  render() {
    const _this$props = this.props,
      {
        className,
        id,
        label,
        selectOnFocus,
        prefix,
        suffix,
        hasFormData,
        size,
        refElem,
        isSmall
      } = _this$props,
      restOfProps = _objectWithoutProperties(_this$props, _excluded);
    const inputProps = _objectSpread(_objectSpread({
      id
    }, restOfProps), selectOnFocus ? {
      onFocus: event => event.target.select()
    } : {});
    const inputElClasses = ['std-input'];
    if (isSmall) {
      inputElClasses.push('std-input-100');
    } else if (size) {
      inputElClasses.push("std-input-".concat(size));
    }
    if (className) {
      inputElClasses.push(className);
    }
    const labelElement = label ? React.createElement("label", {
      htmlFor: id
    }, label) : null;
    const inputField = React.createElement("input", _extends({
      className: inputElClasses.join(' '),
      ref: refElem
    }, inputProps));
    if (suffix || prefix) {
      return React.createElement(React.Fragment, null, labelElement, React.createElement("div", {
        className: "has-input-action ".concat(prefix && 'has-prefix')
      }, inputField, prefix && React.createElement("div", {
        className: "input-prefix"
      }, prefix), suffix && React.createElement("div", {
        className: "input-suffix"
      }, suffix)));
    }
    return React.createElement(React.Fragment, null, labelElement, inputField);
  }
}
Input.defaultProps = {
  className: '',
  hasFormData: false,
  isSmall: false,
  prefix: null,
  refElem: null,
  selectOnFocus: false,
  suffix: null
};
svs.ui.form.Input = Input; 

svs.ui = svs.ui || {};
svs.ui.reactForm = svs.ui.reactForm || {};
svs.ui.reactForm.Input = Input;

 })(window);